import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import { Provider } from 'react-redux'
import store from './modules/store'


import {
    BrowserRouter as Router
  } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

if (module.hot) {
  module.hot.accept();
}
if(process.env.REACT_APP_ENV == 'dev') {
  window.firebase.auth().useEmulator("http://localhost:9099");
  window.firebase.firestore().useEmulator("localhost", 8080);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
